.menu__container {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;

  ul {
    top: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;

    a {
      align-self: center;
      justify-self: center;
      text-decoration: none;
    }

    li {
      font-family: 'Prompt', sans-serif;
      text-align: center;
      font-size: calc(11px + .6vw);
      font-weight: 200;
      list-style: none;
      color: white;
      cursor: pointer;
      transition: all .3s;
      align-self: center;
      justify-self: center;


      p {
        text-shadow: 0 2px 2px rgba(black, 1);
      }

      &:hover {
        font-weight: 600;
      }
    }
  }
}