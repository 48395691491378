.mumbo__jumbo {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .content__container {
        position: absolute;
        max-width: 1440px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        font-family: sans-serif;

        h1 {
            position: absolute;
            top: 15%;
            left: 10%;
            font-family: 'Smooch', cursive;
            color: rgb(129, 175, 215);
            font-size: 7vw;
            text-shadow: 0 0 20px #000000;
            z-index: 1;
    margin-bottom: 5vw;
        }

        .bull__shit {
            width: 100%;
            padding: 3vw 0 0 10vw;

            .lapy {
                position: absolute;
                top: 48%;
                left: 45%;
                transform: translate(-50%, -50%);
                opacity: .3;
                z-index: -1;
                background-blend-mode: darken;
            }

            .numery {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(3) rotate(0deg);
                opacity: .3;
                z-index: -1;
                background-blend-mode: lighten;
                animation: numbers 90s linear infinite;

                @keyframes numbers {

                    0% {
                        transform: translate(-50%, -50%) scale(3) rotate(0deg);
                    }

                    100% {
                        transform: translate(-50%, -50%) scale(3) rotate(-360deg);
                    }

                }
            }

            .copy {
                width: 90%;
                margin-top: 6vw;

                p {
                    font-family: 'Roboto', sans-serif;
                    font-size: calc(10px + 1vw);
                    font-weight: 200;
                    color: white;
                    padding-left: 6vw;
                    line-height: 1.3;
                    text-shadow: 0 0 30px #000000;
                    margin-bottom: 1vw;
                }
            }
        }


    }
}