.about__me {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .content__container {
    position: absolute;
    max-width: 1440px;
    height: 100%;
    padding: 0 1vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      font-family: 'Smooch', cursive;
      color: rgb(129, 175, 215);
      font-size: 8vw;
      text-shadow: 0 0 20px #000000;

    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: calc(12px + .6vw);
      font-weight: 200;
      color: white;
      padding-left: 2vw;
      line-height: 1.1;
      text-shadow: 0 0 30px #000000;
    }

    .podpis {
      position: absolute;
      width: 25%;
      bottom: 5%;
      right:10%;
    }

  }
}