.page__container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vh;
    font-family: 'Roboto', sans-serif;
    text-shadow: 0 0 5px rgba(black, 1);



    p {
        color: white;
        line-height: 1.4;
        font-weight: 200;
        padding: 0 2vh;
        font-size: calc(8px + 1vh);
    }

    h1 {
        text-align: left;
        font-size: calc(25px + 5vh);
        font-family: 'Smooch', cursive;
        color: rgb(129, 175, 215);
    }
img {
    width: 80%;
}
}