.slider__container {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    transform: translateX(0%);
    transition: all .3s;
    display: flex;
    flex-direction: column;
    padding: 25vh 5vh;

    a {
        text-decoration: none;

        h5 {
            font-family: 'Prompt', sans-serif;
            font-size: calc(10px + 2vh);
            margin-bottom: 5vh;
            font-weight: normal;
            text-decoration: none;
            color: black;

            &:hover {
                color: grey;
                text-decoration: underline;
            }
        }
    }

    &.open {
        transform: translateX(100%);
    }
}