.swiper {
    height: 60%;
    border-radius: 3%;
    background-color: black;
    box-shadow: 0 10px 10px rgba(black, 0.5);
}

.swiper-slide {
    text-align: center;
    font-size: 18px;


    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: auto;
    height: 100%;
}
