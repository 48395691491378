    .btn {
        width: 70%;
        height: 7vh;
        border: .5px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3vh 0;
        background-color: rgba(0, 33, 71, 0.5);
        font-size: calc(8px + 2vh);
        font-family: 'Prompt', sans-serif;
        font-weight: 100;
        text-transform: uppercase;
        color: white;
a {
    color: white;
    text-decoration: none;
}
    }