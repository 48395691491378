.for__you {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .content__container {
        position: absolute;
        max-width: 1440px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        font-family: sans-serif;

        h1 {
            position: absolute;
            top: 15%;
            left: 10%;
            font-family: 'Smooch', cursive;
            color: rgb(129, 175, 215);
            font-size: 7vw;
            text-shadow: 0 0 20px #000000;
            z-index: 1;

        }

        p {
            font-family: 'Roboto', sans-serif;
            font-size: 1vw;
            font-weight: 200;
            color: white;
            padding-left: 6vw;
            line-height: 1.3;
            text-shadow: 0 0 30px #000000;
        }

        .podpis {
            position: absolute;
            width: 30%;
            bottom: 3%;
            right: 3%;
        }

    }

    .background {
        position: absolute;
        width: auto;
        height: 100vh;
        z-index: -4;
    }
}
.cards__container {
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3vw;

    &.business {
        width: 70%;

        img {
            max-width: 23%;

        }
    }

    img {
        max-width: 15%;
        transition: all .5s;
        filter: drop-shadow(0px 0px 5px rgba(black, .5));

        &:nth-of-type(1) {
            transform: scale(1) rotate(-10deg);
        }

        &:nth-of-type(2) {
            transform: scale(1) rotate(5deg);
        }

        &:nth-of-type(3) {
            transform: scale(1) rotate(-3deg);
        }

        &:nth-of-type(4) {
            transform: scale(1) rotate(7deg);
        }

        &:nth-of-type(5) {
            transform: scale(1) rotate(-7deg);
        }

        &:nth-of-type(6) {
            transform: scale(1) rotate(10deg);
        }

        &:nth-of-type(7) {
            transform: scale(1) rotate(-10deg);
        }


        &:hover {
            z-index: 1;
            transform: scale(2) rotate(0);
            filter: drop-shadow(10px 10px 20px rgba(black, 1));
        }
    }
}