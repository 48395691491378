:root {
  --time: 22s;
  --offset: 160%;
}

.banner__container {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: -998;

  .moon {
    position: absolute;
    left: 31%;
    top: 45%;
    transform: translate(-50%, -50%) scale(.9);
    z-index: -3;
  }


  .clouds {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20%;



    .cloud {
      position: absolute;
      top: 5%;
      max-width: 70%;
      mix-blend-mode: normal;


      &:nth-of-type(1) {
        animation: clouds1 var(--time) linear infinite;
        z-index: 0;

        @keyframes clouds1 {
          0% {
            transform: translateX(calc(var(--offset) * -1));
          }

          100% {
            transform: translateX(var(--offset));
          }
        }
      }

      &:nth-of-type(2) {
        animation: clouds2 calc(var(--time) * 1.5) linear infinite;
        z-index: 1;

        @keyframes clouds2 {
          0% {
            transform: translateX(calc(var(--offset) * -1));
          }

          100% {
            transform: translateX(var(--offset));
          }
        }
      }

      &:nth-of-type(3) {
        animation: clouds3 calc(var(--time) * 2) linear infinite;
        z-index: 4;

        @keyframes clouds3 {
          0% {
            transform: translateX(calc(var(--offset) * -1));
          }

          100% {
            transform: translateX(var(--offset));
          }
        }
      }

      &:nth-of-type(4) {
        animation: clouds4 calc(var(--time) * 2.5) linear infinite;
        z-index: 5;

        @keyframes clouds4 {
          0% {
            transform: translateX(calc(var(--offset) * -1));
          }

          100% {
            transform: translateX(var(--offset));
          }
        }
      }

      &:nth-of-type(5) {
        animation: clouds5 calc(var(--time) * 3) linear infinite;
        z-index: 6;

        @keyframes clouds5 {
          0% {
            transform: translateX(calc(var(--offset) * -1));
          }

          100% {
            transform: translateX(var(--offset));
          }
        }
      }
    }
  }


  .background__image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -999;
  }


}

.content__container {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 70%;
  height: 100%;

  .logo__container {
    position: absolute;
    width: 60%;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: -5;


    .logo {
      height: 50%;
    }


  }
}

.buttons {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 50%;
  width: 70%;
  z-index: 1000;
  top: 75%;
  transform: translate(-50%, -50%);

  a {
    text-decoration: none;
    color: white;
  }

  .btn {
    color: white;
    width: 20vw;
    border: .5px solid white;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Prompt, sans-serif;
    font-size: 1.1vw;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 4px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(black, 0.3);
    transition: all .5s;

    &:hover {
      box-shadow: 0 20px 20px rgba(black, 0.3);
    }

    &.oferta {
      background-color: #001934;


      &:hover {
        background-color: #2c435d;
      }
    }

    &.spotkanie {
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: #2c435d;
      }
    }
  }
}