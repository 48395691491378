    .burger {
        position: fixed;
        width: 10vh;
        right:3vh;
        top: 5vh;
        height: 4vh;
        cursor: pointer;
        transform: rotate(0deg);
        fill: white;
        transition: all .3s;
        z-index: 999;

        &.rotate {
            transform: rotate(90deg);
            filter: brightness(0)
        }
    }