.content {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 998;
}

.content__background {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -999;

    .background__sky {
        width: 100%;
        height: 100%;

    }

    .zodiak {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        width: auto;
        height: 230%;
        opacity: 0.1;
        mix-blend-mode: lighten;
        animation: zodiak2 90s linear infinite;

        @keyframes zodiak2 {
            0% {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            100% {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }
    }
}