.page__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    margin-bottom: 2vh;
    z-index: 100;
}