.form__mobile {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh 0;

    input {
        width: 80%;
        height: 40px;
        padding: 10px;
        margin-bottom: 35px;

    }

    button {
        width: 50%;
        height: 40px;
    }
}

.confirmation {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .confirmation__field {
        position: relative;
        font-family: 'Prompt', sans-serif;
        width: 80%;
        height: 60%;
        background-color: rgba(white, .8);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10%;
        text-align: center;

        &.browser {
            width: 50%;

            p {
                font-size: calc(12px + 1vw);
            }

            .close {
                width: 3vw;
            }

            img {
                width: 50%;
                margin-left: 25vw;
                margin-top: 3vw;
            }
        }

        p {
            margin-bottom: 10px;
        }

        .close {
            top: 0;
            right: 5%;
            position: absolute;
            width: 12%;
            fill: rgb(126, 126, 126);
            cursor: pointer;
        }

        img {
            margin-top: 20px;
            width: 80%;
            filter: brightness(0);
        }
    }
}

input[type='date'],
input[type='time'] {
    -webkit-appearance: none;
}

.page__container_meeting {
    width: 100vw;
    height: 100vh;
    padding: 10vh 5vw;

    h1 {
        font-size: calc(20px + 3vh);
        font-family: 'Smooch', cursive;
        color: rgb(129, 175, 215);
    }

    p {
        font-size: calc(8px + .4vh);
        text-shadow: 0 5px 10px rgba(0, 0, 0, 1);
    }
}