.appointment {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .appointment__container {
        position: absolute;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        padding: 0 0 2vw 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        font-size: 1vw;
        font-weight: 200;
        color: white;

        h1 {
            font-family: 'Smooch', cursive;
            color: rgb(129, 175, 215);
            font-size: calc(25px + 5vw);
            text-shadow: 0 0 20px #000000;
        }

        p {
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            font-size: calc(10px + .5vw);
            text-align: left;

            &.podpis {
                text-align: right;
            }
        }
    }
}

ul {
    padding: 1vw 2vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:nth-of-type(1) {
        margin-top: 3vw;
    }

    &:nth-last-of-type(1) {
        margin-bottom: 2vw;
    }

    li {
        margin-left: 2vw;
    }
}

a {
    color: aqua;
    margin-left: .5vw;
}

h4 {
    font-size: calc(12px + .5vw);
    margin-bottom: .5vw;
}