.mobileMenu__container {
    position: fixed;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2vh 3vh;
}

.screen__container {
    position: absolute;
    width: 100vw;
    height: auto;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 0 20px rgb(0, 21, 46));

    img {
        width: 100%;
    }
}