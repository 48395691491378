.mobileBackground__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -5;



    .pict__background {
        // width: auto;
        height: 120%;
        min-height: 100%;
        position: fixed;

        &.moon {
            filter: brightness(.6);
        }

        &.zodiak {
            animation: zodiak 90s linear infinite;
            mix-blend-mode: multiply;

            @keyframes zodiak {
                0% {
                    transform: rotate(0);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}